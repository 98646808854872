import { colors, CssVarsThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import DemoLogo from '@/app/[tenantAlias]/demo/_assets/demo-logo.svg';
import {
  createMetadata,
  createOrganizationJSONLD,
} from '@/global/config/ConfigHelper';
import { SPSConfig } from '@/global/types/SPSConfig';
import { globalComponentsMap } from '@/globalComponents/global-components-map';
import { TenantAlias } from '@/shared/tenants-alias-map';

const TENANT_ALIAS: TenantAlias = 'demo';

/**
 * Copy and paste the generated theme config from the Figma design using `Material UI Sync plugin`.
 * @see https://mui.com/material-ui/design-resources/material-ui-sync/
 */
const themeFromFigma: CssVarsThemeOptions = {};

/**
 * Any local overrides can be applied here:
 */
const localOverrides: CssVarsThemeOptions = {
  colorSchemes: {
    light: {
      palette: {
        // @ts-expect-error don't know how to properly fix it right now, needed only for demo
        extra: {
          main: '#E0094D',
          dark: '#D2153D',
          light: '#FF6F8F',
          contrastText: colors.common.white,
        },
      },
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'lg', // without this vertical widget covers page content on laptop screens
      },
    },
  },
};

const HUMAN_READABLE_TENANT_NAME = 'Demo Sales Pilot';
const SITE_NAME = `${HUMAN_READABLE_TENANT_NAME} Site`;
const HOST = `https://${TENANT_ALIAS}.salespilotsites.com`;
const SITE_DESCRIPTION = `A demo sales pilot instance`;
const EMAIL = 'sales@demo.salespilotsites.com';
const PHONE = '+123-456-7890';
const STREET = '123 Main Street';
const CITY = 'Springfield';
const STATE = 'California';
const ZIP = '12345';
const COUNTRY = 'USA';

export const spsConfig: SPSConfig = {
  tenantAlias: TENANT_ALIAS,
  humanReadableTenantName: HUMAN_READABLE_TENANT_NAME,
  siteName: SITE_NAME,
  host: HOST,
  siteDescription: SITE_DESCRIPTION,
  email: EMAIL,
  phone: PHONE,
  street: STREET,
  city: CITY,
  state: STATE,
  zip: ZIP,
  country: COUNTRY,
  tenantTheme: deepmerge(themeFromFigma, localOverrides),
  metadata: createMetadata(
    TENANT_ALIAS,
    SITE_NAME,
    HOST,
    SITE_DESCRIPTION,
    HUMAN_READABLE_TENANT_NAME,
  ),
  organizationJSONLD: createOrganizationJSONLD(
    TENANT_ALIAS,
    SITE_NAME,
    HOST,
    SITE_DESCRIPTION,
    EMAIL,
    PHONE,
    {
      streetAddress: STREET,
      addressLocality: CITY,
      addressRegion: STATE,
      postalCode: ZIP,
      addressCountry: COUNTRY,
    },
  ),
  componentDirectory: globalComponentsMap,
  headerLogo: DemoLogo,
  headerLogoWidth: 234,
  headerLogoHeight: 105,
  headerLinks: [
    {
      href: '/',
      label: 'Home',
    },
    {
      href: '/services',
      label: 'Services',
    },
    {
      href: '/about',
      label: 'About us',
    },
    {
      href: '/careers',
      label: 'Careers',
    },
    {
      href: '/contact',
      label: 'Contact',
    },
  ],
  footerLogo: DemoLogo,
  footerLogoWidth: 234,
  footerLogoHeight: 105,
  footerText: 'Your text here',
  footerLinks: [
    { label: 'Home', href: '/' },
    { label: 'About Us', href: '/about' },
    { label: 'Contact Us', href: '/contact' },
    { label: 'Value Added Services', href: '/services' },
  ],
};
